<template>
  <div class="page">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'Backups',
};
</script>
<style lang="scss" scoped>
.page {
  // max-width: 750px;
  height: 100%;
  margin: auto;
}
</style>
